import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import PageTitle from '../../components/PageTitle';
import NestedMenu from '../../components/NestedMenu';
// import DocsSearch from '../../components/DocsSearch';
import Cta from '../../components/Cta';
import Accordion from '../../components/Accordion';
import LandingMenu from '../../components/LandingMenu';
import Breadcrumbs from '../../components/Breadcrumbs';
import './Docs.scss';

function stripHtml(html){
  var stripped = new DOMParser().parseFromString(html, 'text/html');
  return stripped.body.textContent || '';
}

function firstParagraph(input) {
  let regex = /[<p>].*[</p>]/;
  var match = regex.exec(input);
  return (match !== null ? match : '');
}

export default class DocsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      description: '',
    }
  }

  componentDidMount() {
    this.setState({
      description: stripHtml(firstParagraph(this.props.data.pageMarkdown.html))
    });
  }

  render() {
    const { frontmatter, html: body } = this.props.data.pageMarkdown;
    const { frontmatter: menu } = this.props.data.file.childMarkdownRemark;
    const { frontmatter: cta } = this.props.data.ctaMarkdown;
    const description = this.state.description;

    return (
      <Layout color="4">
        <Helmet title={`${frontmatter.title} | ApplyCycle Documentation`}>
          <meta name="description" content={ description } />
        </Helmet>
        <PageTitle className="Docs__title">
          <h2>Documentation</h2>
          <h3>
            { frontmatter.docsType === 'docs' && frontmatter.docs_section }
            { frontmatter.docsType === 'docsSubLanding' && frontmatter.title }
          </h3>
        </PageTitle>
        <div className="Docs">
          <div className="Docs__wrapper">
            <section className="Docs__content">
              { frontmatter.docsType === 'docs' &&
                <div>
                  <h1 className="Docs__page-title">{ frontmatter.title }</h1>
                  <div className="Docs__content-body" dangerouslySetInnerHTML= {{ __html: body }} />
                  <div className="Docs__content-accordions">
                    { frontmatter.accordion.map(item => (
                      <Accordion
                        id={ item.id }
                        summary={ item.label }
                        content={ item.content }
                        key={ item.label }
                      />
                    ))}
                  </div>
                </div>
              }
              { frontmatter.docsType === 'docsSubLanding' &&
              <div>
                <div className="Docs__content-body" dangerouslySetInnerHTML= {{ __html: body }} />
                <LandingMenu
                  data={ menu }
                  pathPrefix="docs"
                  topLevelPath={ frontmatter.path }
                />
              </div>
              }
            </section>
            <section className="Docs__breadcrumb">
              <Breadcrumbs />
            </section>
            <aside className="Docs__sidebar" >
              {
                //<DocsSearch />
              }
              <NestedMenu data={ menu } pathPrefix="docs" />
            </aside>
          </div>
        </div>
        <Cta
          title={ cta.title }
          buttonLink={ cta.button.link }
          buttonText={ cta.button.text }
        />
      </Layout>
    )
  }
};

export const docsPageQuery = graphql`
  query DocsPage($path: String!) {
    pageMarkdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        docsType
        title
        path
        docs_section
        accordion {
          id
          label
          content
        }
      }
      html
    }
    ...docsMenu
    ...cta
  }
`
