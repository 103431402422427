import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../Markdown';
import './Accordion.scss';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    this.setState({
      open: window.location.hash === `#${this.props.id}`
    })
  }

  toggleOpen(e) {
    e.preventDefault();

    if (e.keyCode) {
      if (e.keyCode === 32 || e.keyCode === 13) {
        this.setState(prevState => ({
          open: !prevState.open,
        }))
      }
    }
    else {
      this.setState(prevState => ({
        open: !prevState.open,
      }))
    }
  }

  static propTypes = {
    id: PropTypes.string,
    summary: PropTypes.string,
    content: PropTypes.string,
  }

  static defaultProps = {
    id: '',
    summary: '',
    content: '',
  }

  render() {
    return (
      <details className="Accordion" id={ this.props.id } open={ this.state.open }>
        <summary className="Accordion__summary" onClick={ this.toggleOpen }>{ this.props.summary }</summary>
        <Markdown className="Accordion__content" content={ this.props.content } />
      </details>
    )
  }
}

export default Accordion;
