import React from 'react';
import PropTypes from 'prop-types';
import './PageTitle.scss';

const PageTitle = ({className, children}) => (
  <div className={`PageTitle ${className}`}>
    { children }
  </div>
)

PageTitle.propTypes = {
  className: PropTypes.string,
}

PageTitle.defaultProps = {
  className: '',
}

export default PageTitle;
