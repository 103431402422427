import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './Button.scss';

const Button = ({color, attributes, link, children}) => {
  let classes = `Button -${color}`;

  if (link !== null) {
    return (
      <Link to={ link } className={ classes } { ...attributes }>
        { children }
      </Link>
    )
  }
  else {
    return (
      <button className={ classes } { ...attributes }>
        { children }
      </button>
    )
  }
};

Button.propTypes = {
  color: PropTypes.oneOf([
    'navy',
    'white',
    'gold'
  ]),
  link: PropTypes.string,
  attributes: PropTypes.object
};

Button.defaultProps = {
  color: 'white',
  link: null,
  attributes: null
};

export default Button;
