import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import './Cta.scss';

const Cta = ({title, buttonLink, buttonText}) => (
  <section className="Cta">
    <div className="Cta__wrapper">
      <h2 className="Cta__title">{ title }</h2>
      <Button link={ buttonLink } color="gold">
        { buttonText }
      </Button>
    </div>
  </section>
)

Cta.propTypes = {
  title: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
}

Cta.defaultProps = {
  title: '',
  buttonLink: '',
  buttonText: '',
}

export default Cta;
